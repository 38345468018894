import React from 'react';

import { SecondaryNav } from '../components/secondaryNav';

export function Content({ children }) {
  return (
    <div className="content">
      <div className="content__text">{children}</div>
      <div className="content__nav">
        <SecondaryNav />
      </div>
    </div>
  );
}
