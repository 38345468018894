import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Periodoncia';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          La enfermedad periodontal describe un grupo de procesos que afectan los tejidos que soportan y rodean los
          dientes. Esta enfermedad puede estar influenciada por factores locales y/o sistémicos. Uno de los factores
          locales de mayor importancia es la falta de higiene.
        </p>
        <p>Los dos tipos más comunes de enfermedad periodontal son la gingivitis y la periodontitis.</p>
        <p>
          La gingivitis es una enfermedad reversible y consiste en una inflamación de los tejidos que rodean los
          dientes. Los sujetos con gingivitis tienen las encías blandas, edematosas y rojizas que pueden sangrar bajo
          una presión moderada como es el cepillado de dientes.{' '}
        </p>
        <p>
          La periodontitis es una condición inflamatoria progresiva que destruye las fibras de los ligamentos
          periodontales y el hueso alveolar que sostienen al diente y puede eventualmente ocasionar la pérdida de los
          mismos.
        </p>
        <p>
          Estas enfermedades son las causas más importantes de pérdida dentaria en adultos, afectando a 3 de cada 4
          personas en algún momento de sus vidas.
        </p>
        <p>
          La periodoncia es la especialidad médica de la Odontología que estudia la prevención, diagnóstico y
          tratamiento de dichas enfermedades, para el mantenimiento de la salud, función y estética de los dientes y sus
          tejidos adyacentes.
        </p>
        <p>
          Hoy en día con el advenimiento de la implantología la periodoncia también es la encargada de la prevención y
          tratamiento de las enfermedades periimplantares como la periimplantitis.
        </p>

        <h2>Preguntas frecuentes</h2>
        <h3>¿Cuáles son las causas de las enfermedades periodontales?</h3>
        <p>
          En la cavidad bucal circulan más de 300 tipos diferentes de bacterias. Las mismas se depositan en la
          superficie de los dientes y en los surcos gingivales. Ello constituye la placa bacteriana. El desarrollo de
          las bacterias suele producir lesiones en los tejidos periodontales. Ello se suma a la natural disposición que
          cada persona posee para impulsar y padecer la enfermedad. Los fumadores tienen una manifiesta tendencia a la
          enfermedad.
        </p>
        <h3>¿Cuáles son las consecuencias si no se trata la enfermedad periodontal?</h3>
        <p>
          La falta de tratamiento a término produce la pérdida de piezas dentales. Tal consecuencia puede variar en
          tiempo pero en estados avanzados de enfermedad suele ser irremediable. La falta de piezas dentales es un
          problema de salud de primer orden que provoca severas secuelas funcionales, estéticas y psicológicas. En
          ciertos casos las enfermedades periodontales pueden provocar sangrado espontáneo de las encías y también la
          misma manifestación ante el cepillado. En otros casos se manifiesta con la aparición de pus en la encia.
          Además se puede padecer halitosis (mal aliento), enrojecimiento de las encías, retracción y movimiento de las
          piezas dentales.
        </p>
        <h3>¿Cuáles son los posibles efectos secundarios de las enfermedades periodontales?</h3>
        <p>
          Las enfermedades periodontales en mujeres en período postmenopáusico sin el aporte de complemento hormonal
          pueden originar mayor riesgo de padecer osteoporosis y acarrear la pérdida progresiva de piezas dentales. Por
          otro lado, gracias al desarrollo de estas enfermedades el cuerpo reacciona liberando sustancias químicas que
          perjudican el buen funcionamiento hormonal, endócrino.
        </p>
        <p>
          En pacientes con antecedentes de enfermedades cardiovasculares, las encías enfermas podrían ser la puerta de
          ingreso de bacterias que pasando a la sangre podrían incrementar el riesgo de procesos infecciosos.
        </p>
      </Content>
    </Layout>
  );
}
