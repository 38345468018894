import React from 'react';
import { Link } from 'gatsby';

export function SecondaryNav() {
  return (
    <nav className="secondary-nav">
      <Link activeClassName="active" to="/implantes-dentales/">
        Implantes
      </Link>
      <Link activeClassName="active" to="/ortodoncia-y-ortopedia/">
        Ortodoncia y Ortopedia
      </Link>
      <Link activeClassName="active" to="/blanqueamiento-dental/">
        Blanqueamiento
      </Link>
      <Link activeClassName="active" to="/protesis-dentales/">
        Prótesis dentales
      </Link>
      <Link activeClassName="active" to="/odontopediatria/">
        Odontopediatría
      </Link>
      <Link activeClassName="active" to="/periodoncia/">
        Periodoncia
      </Link>
      <Link activeClassName="active" to="/endodoncia/">
        Endodoncia
      </Link>
      <Link activeClassName="active" to="/rellenos-oseos/">
        Rellenos oseos
      </Link>
      <Link activeClassName="active" to="/cirugia-oral-y-maxilofacial/">
        Cirugía oral y maxilofacial
      </Link>
    </nav>
  );
}
